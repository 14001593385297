import React from "react";
import FooterNav from "../Common/FooterNav";
import profile1 from '../../assets/images/icons/profile1.png'
import { Edit, Box1, Heart, EmptyWallet, Location, Translate, Notification, Setting2, InfoCircle, Call } from "iconsax-react";

function Profile() {
  return (
    <>
      <header class="profile-header section-t-space">
        <div class="custom-container">
          <div class="header-panel">
            <h3>Profile</h3>
          </div>
          <div class="d-flex align-items-center gap-2">
            <div class="profile-pic mt-5">
              <img
                class="img-fluid img"
                src={profile1}
                alt="profile"
              />
            </div>
            <div class="profile-name d-flex align-items-center justify-content-between mt-3 w-100">
              <h4 class="theme-color">Marlin Watkin</h4>
              <a href="profile-setting.html">
                <i class="iconsax edit-icon" data-icon="edit-1"><Edit /></i>
              </a>
            </div>
          </div>
        </div>
      </header>

      <section class="section-b-space pt-0">
        <div class="custom-container">
          <ul class="profile-list">
            <li>
              <a href="order-history.html" class="profile-box">
                <div class="profile-img">
                  <i class="iconsax icon" data-icon="box"><Box1 /></i>
                </div>
                <div class="profile-details">
                  <h4>Orders</h4>
                  <h5>Ongoing orders, Recent orders..</h5>
                </div>
              </a>
            </li>
            <li>
              <a href="wishlist.html" class="profile-box">
                <div class="profile-img">
                  <i class="iconsax icon" data-icon="heart"><Heart /></i>
                </div>
                <div class="profile-details">
                  <h4>Wishlist</h4>
                  <h5>Your save product</h5>
                </div>
              </a>
            </li>
            <li>
              <a href="manage-payment.html" class="profile-box">
                <div class="profile-img">
                  <i class="iconsax icon" data-icon="wallet-open"><EmptyWallet /></i>
                </div>
                <div class="profile-details">
                  <h4>Payment</h4>
                  <h5>Saved card, Wallets</h5>
                </div>
              </a>
            </li>
            <li>
              <a href="manage-address.html" class="profile-box">
                <div class="profile-img">
                  <i class="iconsax icon" data-icon="location"><Location /></i>
                </div>
                <div class="profile-details">
                  <h4>Saved Address</h4>
                  <h5>Home, Office</h5>
                </div>
              </a>
            </li>
            <li>
              <a href="language.html" class="profile-box">
                <div class="profile-img">
                  <i class="iconsax icon" data-icon="translate"><Translate /></i>
                </div>
                <div class="profile-details">
                  <h4>Language</h4>
                  <h5>Select your language here</h5>
                </div>
              </a>
            </li>
            <li>
              <a href="notification.html" class="profile-box">
                <div class="profile-img">
                  <i class="iconsax icon" data-icon="bell-1"><Notification /></i>
                </div>
                <div class="profile-details">
                  <h4>Notification</h4>
                  <h5>Offers, Order tracking messages</h5>
                </div>
              </a>
            </li>
            <li>
              <a href="setting.html" class="profile-box">
                <div class="profile-img">
                  <i class="iconsax icon" data-icon="setting-1"><Setting2 /></i>
                </div>
                <div class="profile-details">
                  <h4>Settings</h4>
                  <h5>app settings, Dark mode</h5>
                </div>
              </a>
            </li>
            <li>
              <a href="terms-conditions.html" class="profile-box">
                <div class="profile-img">
                  <i class="iconsax icon" data-icon="info-circle"><InfoCircle /></i>
                </div>
                <div class="profile-details">
                  <h4>Terms & Conditions</h4>
                  <h5>T&C for use of platform</h5>
                </div>
              </a>
            </li>
            <li class="border-bottom-0">
              <a href="help.html" class="profile-box">
                <div class="profile-img">
                  <i class="iconsax icon" data-icon="phone"><Call /></i>
                </div>
                <div class="profile-details">
                  <h4>Help</h4>
                  <h5>Customer Support, FAQs</h5>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </section>

      <section class="panel-space"></section>
      <FooterNav />
    </>
  );
}

export default Profile;
