import React from "react";
import product6 from "../../../assets/images/product/6.png";
import product7 from "../../../assets/images/product/7.png";
import product8 from "../../../assets/images/product/8.png";
import product9 from "../../../assets/images/product/9.png";
import start from "../../../assets/images/svg/Star.svg";

import { Swiper, SwiperSlide } from "swiper/react";
import "../../../assets/css/vendors/swiper-bundle.min.css";
import { Bag2 } from "iconsax-react";

function OfferSection() {
  return (
    <>
      <section class="offer-zone section-b-space pt-0">
        <div class="custom-container">
          <div class="title">
            <h2>Offer Zone</h2>
            <a href="product-details.html">View All</a>
          </div>
          <Swiper
            slidesPerView={1.5}
            spaceBetween={20}
            loop={true}
            className="offer"
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              375: {
                slidesPerView: 1.2,
              },
              425: {
                slidesPerView: 1.5,
              },
              768: {
                slidesPerView: 2,
              },
            }}
          >
            <SwiperSlide>
              <div class="horizontal-product-box">
                <a href="product-details.html" class="horizontal-product-img">
                  <img class="img-fluid img" src={product6} alt="p6" />
                </a>
                <div class="horizontal-product-details">
                  <div class="d-flex align-items-center justify-content-between">
                    <h4>Table Lamp</h4>
                  </div>
                  <h5>Bedroom Study Table Lamp</h5>
                  <div class="rating d-flex align-items-center gap-1 mt-1">
                    <img src={start} alt="star" />

                    <img src={start} alt="star" />

                    <img src={start} alt="star" />

                    <img src={start} alt="star" />

                    <img src={start} alt="star" />
                  </div>

                  <div class="d-flex align-items-center justify-content-between mt-1">
                    <div class="d-flex align-items-center gap-2">
                      <h3 class="fw-semibold">$37</h3>
                    </div>
                    <a href="cart.html" class="cart-bag">
                      <i class="iconsax bag" data-icon="basket-2"><Bag2 /></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="horizontal-product-box mt-3">
                <a href="product-details.html" class="horizontal-product-img">
                  <img class="img-fluid img" src={product7} alt="p7" />
                </a>
                <div class="horizontal-product-details">
                  <div class="d-flex align-items-center justify-content-between">
                    <h4>Side Table</h4>
                  </div>
                  <h5>Solid wood console table</h5>
                  <div class="rating d-flex align-items-center gap-1 mt-1">
                    <img src={start} alt="star" />

                    <img src={start} alt="star" />

                    <img src={start} alt="star" />

                    <img src={start} alt="star" />

                    <img src={start} alt="star" />
                  </div>

                  <div class="d-flex align-items-center justify-content-between mt-1">
                    <div class="d-flex align-items-center gap-2">
                      <h3 class="fw-semibold">$37</h3>
                    </div>
                    <a href="cart.html" class="cart-bag">
                      <i class="iconsax bag" data-icon="basket-2"><Bag2 /></i>
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="horizontal-product-box">
                <a href="product-details.html" class="horizontal-product-img">
                  <img class="img-fluid img" src={product8} alt="p8" />
                </a>
                <div class="horizontal-product-details">
                  <div class="d-flex align-items-center justify-content-between">
                    <h4>Lounge Chair</h4>
                  </div>
                  <h5>Modern arms chair</h5>
                  <div class="rating d-flex align-items-center gap-1 mt-1">
                    <img src={start} alt="star" />

                    <img src={start} alt="star" />

                    <img src={start} alt="star" />

                    <img src={start} alt="star" />

                    <img src={start} alt="star" />
                  </div>

                  <div class="d-flex align-items-center justify-content-between mt-1">
                    <div class="d-flex align-items-center gap-2">
                      <h3 class="fw-semibold">$37</h3>
                    </div>
                    <a href="cart.html" class="cart-bag">
                      <i class="iconsax bag" data-icon="basket-2"><Bag2 /></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="horizontal-product-box mt-3">
                <a href="product-details.html" class="horizontal-product-img">
                  <img class="img-fluid img" src={product9} alt="p9" />
                </a>
                <div class="horizontal-product-details">
                  <div class="d-flex align-items-center justify-content-between">
                    <h4>Swing Chair</h4>
                  </div>
                  <h5>Modern steel swing chair</h5>
                  <div class="rating d-flex align-items-center gap-1 mt-1">
                    <img src={start} alt="star" />

                    <img src={start} alt="star" />

                    <img src={start} alt="star" />

                    <img src={start} alt="star" />

                    <img src={start} alt="star" />
                  </div>

                  <div class="d-flex align-items-center justify-content-between mt-1">
                    <div class="d-flex align-items-center gap-2">
                      <h3 class="fw-semibold">$37</h3>
                    </div>
                    <a href="cart.html" class="cart-bag">
                      <i class="iconsax bag" data-icon="basket-2"><Bag2 /></i>
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            
          </Swiper>
        </div>
      </section>
    </>
  );
}

export default OfferSection;
