import React from "react";
import banner3 from '../../../assets/images/banner/banner-3.jpg'
import banner4 from '../../../assets/images/banner/banner-4.jpg'
import { ArrowRight } from 'iconsax-react';

function CustomSection() {
  return (
    <>
      <section class="banner-wapper grid-banner">
        <div class="custom-container">
          <div class="row">
            <div class="col-6">
              <div class="banner-bg">
                <img
                  class="img-fluid img-bg"
                  src={banner3}
                  alt="banner-3"
                />
                <div class="banner-content">
                  <h3>Wingback Chair</h3>
                </div>
                <a href="shop.html" class="more-btn d-block">
                  <i class="iconsax right-arrow" data-icon="arrow-right"><ArrowRight /></i>
                  <h3>View More</h3>
                </a>
                <div class="banner-bg"></div>
              </div>
            </div>

            <div class="col-6">
              <div class="banner-bg">
                <img
                  class="img-fluid img-bg"
                  src={banner4}
                  alt="banner-3"
                />
                <div class="banner-content">
                  <h3>Wingback Chair</h3>
                </div>
                <a href="shop.html" class="more-btn d-block">
                  <i class="iconsax right-arrow" data-icon="arrow-right"><ArrowRight /></i>
                  <h3>View More</h3>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CustomSection;
