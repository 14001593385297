import React, { useEffect, useState } from "react";
import homeSvg from "../../../assets/images/svg/home.svg";
import homeSvgFill from "../../../assets/images/svg/home-fill.svg";
import CategorySvg from "../../../assets/images/svg/categories.svg";
import CategorySvgFill from "../../../assets/images/svg/categories-fill.svg";
import bagSvg from "../../../assets/images/svg/bag.svg";
import bagSvgFill from "../../../assets/images/svg/bag-fill.svg";
import hartsvg from "../../../assets/images/svg/heart.svg";
import hartsvgFill from "../../../assets/images/svg/heart-fill.svg";
import ProfileSvg from "../../../assets/images/svg/profile.svg";
import profileSvgFill from "../../../assets/images/svg/profile.svg";
import { Link, useLocation } from "react-router-dom";

function FooterNav() {
  const [active, setActive] = useState("/categories");
  const location = useLocation();

  useEffect(() => {
    if (location) {
      const { pathname } = location;
      setActive(pathname);
    }
  }, [location]);

  return (
    <>
      <div class="navbar-menu">
        <ul>
          <li class={active === "/home" ? "active" : ""}>
            <a href="landing.html"></a>
            <Link to="/home">
              <div class="icon">
                <img class="unactive" src={homeSvg} alt="home" />
                <img class="active" src={homeSvgFill} alt="home" />
              </div>
            </Link>
          </li>
          <li class={active === "/categories" ? "active" : ""}>
            <Link to="/categories">
              <div class="icon">
                <img class="unactive" src={CategorySvg} alt="categories" />
                <img class="active" src={CategorySvgFill} alt="categories" />
              </div>
            </Link>
          </li>
          <li class={active === "/cart" ? "active" : ""}>
            <Link to="/cart">
              <div class="icon">
                <img class="unactive" src={bagSvg} alt="bag" />
                <img class="active" src={bagSvgFill} alt="bag" />
              </div>
            </Link>
          </li>
          <li class={active === "/wishlist" ? "active" : ""}>
            <Link to="/wishlist">
              <div class="icon">
                <img class="unactive" src={hartsvg} alt="heart" />
                <img class="active" src={hartsvgFill} alt="heart" />
              </div>
            </Link>
          </li>
          <li class={active === "/cart" ? "active" : ""}>
            <Link to="/profile">
              <div class="icon">
                <img class="unactive" src={ProfileSvg} alt="profile" />
                <img class="active" src={profileSvgFill} alt="profile" />
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default FooterNav;
