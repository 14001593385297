import React from "react";
import sofa from "../../../assets/images/svg/sofa.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../assets/css/vendors/swiper-bundle.min.css";

function CategorySection() {
  return (
    <>
      <section>
        <div class="custom-container">
          <Swiper
            slidesPerView={4}
            spaceBetween={10}
            loop={true}
            className="categories"
            breakpoints={{
              0: {
                slidesPerView: 3,
              },
              375: {
                slidesPerView: 4,
              },
              767: {
                slidesPerView: 5,
              },
            }}
          >
            <SwiperSlide>
              <a href="#" class="categories-item active">
                <img class="categories-img" src={sofa} alt="sofa" />
                <h4>Sofa</h4>
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="shop.html" class="categories-item ">
                <h4>Chair</h4>
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="shop.html" class="categories-item ">
                <h4>Chair</h4>
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="shop.html" class="categories-item ">
                <h4>Chair</h4>
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="shop.html" class="categories-item ">
                <h4>Chair</h4>
              </a>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
    </>
  );
}

export default CategorySection;
