import React from "react";
import { ArrowLeft, Trash, Add, Minus } from "iconsax-react";
import product7 from '../../assets/images/product/7.png'
import product11 from '../../assets/images/product/11.png'
import product13 from '../../assets/images/product/13.png'

function Cart(props) {

  const goBack = () => {
    window.history.back();
  };
  return (
    <>
      <header class="section-t-space">
        <div class="custom-container">
          <div class="header-panel">
            <a onClick={(e) => goBack(e)}>
              <i class="iconsax back-btn" data-icon="arrow-left">
                <ArrowLeft />
              </i>
            </a>
            <h3>Cart</h3>
          </div>
        </div>
      </header>

      <section>
        <div class="custom-container">
          <ul class="horizontal-product-list">
            <li class="cart-product-box">
              <div class="horizontal-product-box">
                <div class="horizontal-product-img">
                  <a href="product-details.html">
                    <img
                      class="img-fluid img"
                      src={product11}
                      alt="p11"
                    />
                  </a>
                </div>
                <div class="horizontal-product-details">
                  <div class="d-flex align-items-center justify-content-between">
                    <a href="product-details.html">
                      <h4>Lounge Chair</h4>
                    </a>
                    <i class="iconsax trash" data-icon="trash">
                      <Trash />
                    </i>
                  </div>
                  <ul class="product-info">
                    <li>Qty : 1</li>
                    <li>
                      <span class="product-color color1"></span>Blue
                    </li>
                  </ul>

                  <div class="d-flex align-items-center justify-content-between mt-3">
                    <div class="d-flex align-items-center gap-2">
                      <h3 class="fw-semibold">
                        $130 <del class="light-text fw-normal">$160</del>
                      </h3>
                    </div>
                    <div class="plus-minus">
                      <button class="sub plus-minus-button">
                        <i class="iconsax" data-icon="minus">
                          <Minus />
                        </i>
                      </button>
                      <input type="number" value="1" min="1" max="10" />
                      <button class="add plus-minus-button">
                        <i class="iconsax" data-icon="add">
                          <Add />
                        </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="cart-product-box">
              <div class="horizontal-product-box">
                <div class="horizontal-product-img">
                  <a href="product-details.html">
                    <img
                      class="img-fluid img"
                      src={product13}
                      alt="p13"
                    />
                  </a>
                </div>
                <div class="horizontal-product-details">
                  <div class="d-flex align-items-center justify-content-between">
                    <a href="product-details.html">
                      <h4>Hanging Light</h4>
                    </a>
                    <i class="iconsax trash" data-icon="trash">
                      <Trash />
                    </i>
                  </div>
                  <ul class="product-info">
                    <li>Qty : 1</li>
                    <li>
                      <span class="product-color color2"></span>Black
                    </li>
                  </ul>
                  <div class="d-flex align-items-center justify-content-between mt-3">
                    <div class="d-flex align-items-center gap-2">
                      <h3 class="fw-semibold">
                        $30 <del class="light-text fw-normal">$60</del>
                      </h3>
                    </div>
                    <div class="plus-minus">
                      <button class="sub plus-minus-button">
                        <i class="iconsax" data-icon="minus">
                          <Minus />
                        </i>
                      </button>
                      <input type="number" value="1" min="1" max="10" />
                      <button class="add plus-minus-button">
                        <i class="iconsax" data-icon="add">
                          <Add />
                        </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="cart-product-box">
              <div class="horizontal-product-box">
                <div class="horizontal-product-img">
                  <a href="product-details.html">
                    <img
                      class="img-fluid img"
                      src={product7}
                      alt="p7"
                    />
                  </a>
                </div>
                <div class="horizontal-product-details">
                  <div class="d-flex align-items-center justify-content-between">
                    <a href="product-details.html">
                      <h4>Side Table</h4>
                    </a>
                    <i class="iconsax trash" data-icon="trash">
                      <Trash />
                    </i>
                  </div>
                  <ul class="product-info">
                    <li>Qty : 1</li>
                    <li>
                      <span class="product-color color3"></span>Brown
                    </li>
                  </ul>
                  <div class="d-flex align-items-center justify-content-between mt-3">
                    <div class="d-flex align-items-center gap-2">
                      <h3 class="fw-semibold">
                        $50 <del class="light-text fw-normal">$80</del>
                      </h3>
                    </div>
                    <div class="plus-minus">
                      <button class="sub plus-minus-button">
                        <i class="iconsax" data-icon="minus">
                          <Minus />
                        </i>
                      </button>
                      <input type="number" value="1" min="1" max="10" />
                      <button class="add plus-minus-button">
                        <i class="iconsax" data-icon="add">
                          <Add />
                        </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <div class="pay-popup">
        <div class="price-items">
          <h6>Total price</h6>
          <h2>$210.00</h2>
        </div>
        <a href="checkout.html" class="btn btn-lg theme-btn pay-btn mt-0">
          Checkout
        </a>
      </div>

      <section class="panel-space"></section>
    </>
  );
}

export default Cart;
