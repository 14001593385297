import React from "react";
import { Filter } from "iconsax-react";
import { SearchNormal1 } from "iconsax-react";

function SearchSection() {
  return (
    <>
      <section>
        <div className="custom-container">
          <form className="theme-form search-head" target="_blank">
            <div className="form-group">
              <div className="form-input">
                <input
                  type="text"
                  className="form-control search"
                  id="inputusername"
                  placeholder="Search here..."
                />
                <i class="iconsax filter-icon" data-icon="media-sliders-3">
                  <SearchNormal1 />
                </i>
              </div>

              <a
                href="#search-filter"
                className="btn filter-btn mt-0"
                data-bs-toggle="modal"
              >
                <i className="iconsax filter-icon" data-icon="media-sliders-3">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19 22.75C18.59 22.75 18.25 22.41 18.25 22V11C18.25 10.59 18.59 10.25 19 10.25C19.41 10.25 19.75 10.59 19.75 11V22C19.75 22.41 19.41 22.75 19 22.75Z"
                      fill="#292D32"
                    ></path>
                    <path
                      d="M19 7.75C18.59 7.75 18.25 7.41 18.25 7V2C18.25 1.59 18.59 1.25 19 1.25C19.41 1.25 19.75 1.59 19.75 2V7C19.75 7.41 19.41 7.75 19 7.75Z"
                      fill="#292D32"
                    ></path>
                    <path
                      d="M12 22.75C11.59 22.75 11.25 22.41 11.25 22V17C11.25 16.59 11.59 16.25 12 16.25C12.41 16.25 12.75 16.59 12.75 17V22C12.75 22.41 12.41 22.75 12 22.75Z"
                      fill="#292D32"
                    ></path>
                    <path
                      d="M12 13.75C11.59 13.75 11.25 13.41 11.25 13V2C11.25 1.59 11.59 1.25 12 1.25C12.41 1.25 12.75 1.59 12.75 2V13C12.75 13.41 12.41 13.75 12 13.75Z"
                      fill="#292D32"
                    ></path>
                    <path
                      d="M5 22.75C4.59 22.75 4.25 22.41 4.25 22V11C4.25 10.59 4.59 10.25 5 10.25C5.41 10.25 5.75 10.59 5.75 11V22C5.75 22.41 5.41 22.75 5 22.75Z"
                      fill="#292D32"
                    ></path>
                    <path
                      d="M5 7.75C4.59 7.75 4.25 7.41 4.25 7V2C4.25 1.59 4.59 1.25 5 1.25C5.41 1.25 5.75 1.59 5.75 2V7C5.75 7.41 5.41 7.75 5 7.75Z"
                      fill="#292D32"
                    ></path>
                    <path
                      d="M7 11.75H3C2.59 11.75 2.25 11.41 2.25 11C2.25 10.59 2.59 10.25 3 10.25H7C7.41 10.25 7.75 10.59 7.75 11C7.75 11.41 7.41 11.75 7 11.75Z"
                      fill="#292D32"
                    ></path>
                    <path
                      d="M21 11.75H17C16.59 11.75 16.25 11.41 16.25 11C16.25 10.59 16.59 10.25 17 10.25H21C21.41 10.25 21.75 10.59 21.75 11C21.75 11.41 21.41 11.75 21 11.75Z"
                      fill="#292D32"
                    ></path>
                    <path
                      d="M14 13.75H10C9.59 13.75 9.25 13.41 9.25 13C9.25 12.59 9.59 12.25 10 12.25H14C14.41 12.25 14.75 12.59 14.75 13C14.75 13.41 14.41 13.75 14 13.75Z"
                      fill="#292D32"
                    ></path>
                  </svg>
                </i>
              </a>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default SearchSection;
