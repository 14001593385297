import { toast } from "react-toastify";
import { AuthService } from "./service";
import { isLoading } from "../../../constants/actionConstants";

export const sentOtp = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let ResPonse = await AuthService.sentOtp(params);
    const {status, message} = ResPonse
    if(status) {
        toast.success(message)
    }else {
        toast.error(message)
    }
    
    dispatch(isLoading(false));

    return status

};

export const verifyOtp = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let ResPonse = await AuthService.verifyOtp(params);
    const {status, message} = ResPonse
    if(status) {
        toast.success(message)
    }else {
        toast.error(message)
    }
    dispatch(isLoading(false));

    return status
}