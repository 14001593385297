import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from './Components/Common/Loader/loadingAnimation'
import AppRoutes from "./routes";

function App() {
  return (
    <>
      <Loader />

      <ToastContainer autoClose={5000} />

      <AppRoutes />
    </>
  );
}

export default App;
