import React, { useState } from "react";
import authBg from "../../../assets/images/background/auth_bg.jpg";

import facebookSvg from "../../../assets/images/svg/facebook.svg";
import googleSvg from "../../../assets/images/svg/google.svg";
import appleSvg from "../../../assets/images/svg/apple.svg";
import { useDispatch } from "react-redux";
import { sentOtp, verifyOtp } from "../Dependiences/action";
import { useNavigate } from "react-router-dom";

const Login = (props) => {
  const [formData, setFormData] = useState({});
  const [showOtp, setShowOtp] = useState(false);
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleSendOtp = (e) => {
    e.preventDefault();
    dispatch(sentOtp(formData)).then(res => {
      if(res) {
        setShowOtp(true)
      }
    })
  }

  const handleverifyOtp = (e) => {
    e.preventDefault()
    dispatch(verifyOtp(formData)).then(res => {
      if(res) {
        navigate('/home')
      }
    })
  }
  return (
    <>
      {/* <div className="auth-body"> */}
      <div className="auth-img">
        <img className="img-fluid auth-bg" src={authBg} alt="auth_bg" />
        <div className="auth-content">
          <div>
            <h2>Hello Again!</h2>
            <h4 className="p-0">Welcome back, You have been missed!</h4>
          </div>
        </div>
      </div>

      <form className="auth-form" target="_blank">
        <div className="custom-container">
          {!showOtp ? (
            <div className="form-group">
              <label for="inputusername" className="form-label">
                Mobile
              </label>
              <div className="form-input mb-4">
                <input
                  type="text"
                  className="form-control"
                  id="inputusername"
                  placeholder="Enter Your Mobile"
                  name="mobile"
                  value={formData.mobile}
                  onChange={(e) => handleChange(e)}
                />
                <i className="iconsax icons" data-icon="mail"></i>
              </div>
            </div>
          ) : (
            <div className="form-group">
              <label for="inputPassword" className="form-label">
                OTP
              </label>
              <div className="form-input">
                <input
                  type="text"
                  className="form-control"
                  id="inputPassword"
                  placeholder="Enter Your OTP"
                  name="otp"
                  value={formData.otp}
                  onChange={(e) => handleChange(e)}
                />
                <i className="iconsax icons" data-icon="key"></i>
              </div>
            </div>
          )}

          {/* <div className="option mt-3">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label className="form-check-label" for="flexCheckDefault">
                  Remember me
                </label>
              </div>
              <a className="forgot" href="forgot-password.html">
                Forgot password?
              </a>
            </div> */}

          <div className="submit-btn">
            <a href="#0" onClick={(e) => showOtp ? handleverifyOtp(e) : handleSendOtp(e)} className="btn auth-btn w-100">
              {showOtp ? `Login`: `Request OTP`}
            </a>
          </div>
          <div className="division">
            <span>OR</span>
          </div>

          <ul className="social-media">
            <li>
              <a href="https://www.facebook.com/login/" target="_blank">
                <img
                  className="img-fluid icons"
                  src={facebookSvg}
                  alt="facebook"
                />
              </a>
            </li>
            <li>
              <a href="https://www.google.co.in/" target="_blank">
                <img
                  className="img-fluid icons"
                  src={googleSvg}
                  alt="facebook"
                />
              </a>
            </li>
            <li>
              <a href="https://www.apple.com/in/" target="_blank">
                <img
                  className="img-fluid icons"
                  src={appleSvg}
                  alt="facebook"
                />
              </a>
            </li>
          </ul>

          <h4 className="signup">
            Don’t have an account ?<a href="create-account.html"> Sign up</a>
          </h4>
        </div>
      </form>
      {/* </div> */}
    </>
  );
};

export default Login;
