import Login from "../../Components/Auth/Login";
import Register from "../../Components/Auth/Register";
import Home from "../../Components/Home";
import Categories from '../../Components/Categories'
import Cart from "../../Components/Cart";
import Wishlist from "../../Components/Wishlist";
import Profile from "../../Components/Profile";

export const routes = [
  {
    path: '/',
    element: <Login />,
    protected: false
  },
  {
    path: '/register',
    element: <Register />,
    protected: false
  },
  {
    path: '/home',
    element: <Home />,
    protected: false
  },
  {
    path: '/categories',
    element: <Categories />,
    protected: false
  },
  {
    path: '/Cart',
    element: <Cart />,
    protected: false
  },
  {
    path: '/wishlist',
    element: <Wishlist />,
    protected: false
  },
  {
    path: '/profile',
    element: <Profile />,
    protected: true
  }

];

