import React from "react";
import ProfileImage from '../../../assets/images/icons/profile.png'
import { HambergerMenu, NotificationBing } from 'iconsax-react';

function Header() {
  return (
    <header class="section-t-space">
      <div class="custom-container">
        <div class="header">
          <div class="head-content">
            <button
              class="sidebar-btn"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasLeft"
            >
              <i class="iconsax menu-icon" data-icon="menu-hamburger"><HambergerMenu /></i>
              
            </button>
            <div class="header-info">
              <img
                class="img-fluid profile-pic"
                src={ProfileImage}
                alt="profile"
              />
              <div>
                <h4 class="light-text">Hello</h4>
                <h2 class="theme-color">Agasya!</h2>
              </div>
            </div>
          </div>
          <a href="notification.html" class="notification">
          <i class="iconsax notification-icon" data-icon="bell-2"><NotificationBing /></i>
          </a>
        </div>
      </div>
    </header>
  );
}

export default Header;
