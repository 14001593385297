import React from "react";
import SearchSection from "./SearchSection";
import Sidebar from "../Common/Sidebar";
import Header from "../Common/Header";
import BannerSection from "./BannerSection";
import CategorySection from './CategorySection'
import NewArivals from "./NewArivals";
import TrandingFurniture from "./TrandingFurniture";
import OfferSection from "./OfferSection";
import OtherFurniture from "./OtherFurniture";
import CustomSection from "./CustonSection";
import FooterNav from "../Common/FooterNav";

function Home() {
  return (
    <>
      <Sidebar />
      <Header />
      <SearchSection />
      <BannerSection />
      <CategorySection />
      <NewArivals />
      <TrandingFurniture />
      <BannerSection />
      <OfferSection />
      <OtherFurniture />
      <CustomSection />
      <FooterNav />
      

      <section className="panel-space"></section>
    </>
  );
}

export default Home;
