import React from "react";

function Sidebar(props) {
  return (
    <div
      class="offcanvas sidebar-offcanvas offcanvas-start"
      tabindex="-1"
      id="offcanvasLeft"
    >
      <div class="offcanvas-header">
        <img
          class="img-fluid profile-pic"
          src="assets/images/icons/profile.png"
          alt="profile"
        />
        <h4>Hello, Agasya!</h4>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body">
        <div class="sidebar-content">
          <ul class="link-section">
            <li>
              <div class="pages">
                <h4>RTL</h4>
                <div class="switch-btn">
                  <input id="dir-switch" type="checkbox" />
                </div>
              </div>
            </li>
            <li>
              <div class="pages">
                <h4>Dark</h4>
                <div class="switch-btn">
                  <input id="dark-switch" type="checkbox" />
                </div>
              </div>
            </li>

            <li>
              <a href="page-listing.html" class="pages">
                <h4>Pages List</h4>
                <i class="ri-arrow-drop-right-line"></i>
              </a>
            </li>

            <li>
              <a href="setting.html" class="pages">
                <h4>Setting</h4>
                <i class="ri-arrow-drop-right-line"></i>
              </a>
            </li>

            <li>
              <a href="login.html" class="pages">
                <h4>Logout</h4>
                <i class="ri-arrow-drop-right-line"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
