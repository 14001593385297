import React from "react";
import FooterNav from "../Common/FooterNav";
import { SearchNormal1 , NotificationBing, ArrowRight} from "iconsax-react";
import product3 from '../../assets/images/product/3.png'
import product21 from '../../assets/images/product/21.png'
import product11 from '../../assets/images/product/11.png'
import product22 from '../../assets/images/product/22.png'
import product23 from '../../assets/images/product/23.png'
import product24 from '../../assets/images/product/24.png'
import product25 from '../../assets/images/product/25.png'

function Categories() {
  return (
    <>
      <header class="section-t-space">
        <div class="custom-container">
          <div class="header-panel">
            <h3>Categories</h3>
            <a href="notification.html" class="notification">
              <i class="iconsax notification-icon" data-icon="bell-2"><NotificationBing /></i>
            </a>
          </div>
        </div>
      </header>

      <section>
        <div class="custom-container">
          <form class="theme-form search-head" target="_blank">
            <div class="form-group">
              <div class="form-input w-100">
                <input
                  type="text"
                  class="form-control search"
                  id="inputusername"
                  placeholder="Search here..."
                />
                <i class="iconsax search-icon" data-icon="search-normal-2"><SearchNormal1 /></i>
              </div>
            </div>
          </form>
        </div>
      </section>

      <section>
        <div class="custom-container">
          <ul class="categories-list">
            <li class="mt-0">
              <a href="shop.html" class="d-flex align-items-center">
                <div class="ps-3">
                  <h2>Chairs</h2>
                  <h4 class="mt-1">Total 120 item available</h4>
                  <div class="arrow">
                    <i class="iconsax right-arrow" data-icon="arrow-right"><ArrowRight /></i>
                  </div>
                </div>
                <div class="categories-img">
                  <img
                    class="img-fluid img"
                    src={product3}
                    alt="p3"
                  />
                </div>
              </a>
            </li>
            <li>
              <a href="shop.html" class="d-flex align-items-center">
                <div class="ps-3">
                  <h2>Tables</h2>
                  <h4 class="mt-1">Total 120 item available</h4>
                  <div class="arrow">
                    <i class="iconsax right-arrow" data-icon="arrow-right"><ArrowRight /></i>
                  </div>
                </div>
                <div class="categories-img">
                  <img
                    class="img-fluid categories img"
                    src={product21}
                    alt="p21"
                  />
                </div>
              </a>
            </li>
            <li>
              <a href="shop.html" class="d-flex align-items-center">
                <div class="ps-3">
                  <h2>Sofas</h2>
                  <h4 class="mt-1">Total 120 item available</h4>
                  <div class="arrow">
                    <i class="iconsax right-arrow" data-icon="arrow-right"><ArrowRight /></i>
                  </div>
                </div>
                <div class="categories-img">
                  <img
                    class="img-fluid categories img"
                    src={product11}
                    alt="p11"
                  />
                </div>
              </a>
            </li>
            <li>
              <a href="shop.html" class="d-flex align-items-center">
                <div class="ps-3">
                  <h2>Hanging chairs</h2>
                  <h4 class="mt-1">Total 120 item available</h4>
                  <div class="arrow">
                    <i class="iconsax right-arrow" data-icon="arrow-right"><ArrowRight /></i>
                  </div>
                </div>
                <div class="categories-img">
                  <img
                    class="img-fluid categories img"
                    src={product22}
                    alt="p22"
                  />
                </div>
              </a>
            </li>
            <li>
              <a href="shop.html" class="d-flex align-items-center">
                <div class="ps-3">
                  <h2>Cabinets</h2>
                  <h4 class="mt-1">Total 120 item available</h4>
                  <div class="arrow">
                    <i class="iconsax right-arrow" data-icon="arrow-right"><ArrowRight /></i>
                  </div>
                </div>
                <div class="categories-img">
                  <img
                    class="img-fluid categories img"
                    src={product23}
                    alt="p23"
                  />
                </div>
              </a>
            </li>
            <li>
              <a href="shop.html" class="d-flex align-items-center">
                <div class="ps-3">
                  <h2>Lamp</h2>
                  <h4 class="mt-1">Total 120 item available</h4>
                  <div class="arrow">
                    <i class="iconsax right-arrow" data-icon="arrow-right"><ArrowRight /></i>
                  </div>
                </div>
                <div class="categories-img">
                  <img
                    class="img-fluid categories img"
                    src={product24}
                    alt="p24"
                  />
                </div>
              </a>
            </li>
            <li>
              <a href="shop.html" class="d-flex align-items-center">
                <div class="ps-3">
                  <h2>Cupboard</h2>
                  <h4 class="mt-1">Total 120 item available</h4>
                  <div class="arrow">
                    <i class="iconsax right-arrow" data-icon="arrow-right"><ArrowRight /></i>
                  </div>
                </div>
                <div class="categories-img">
                  <img
                    class="img-fluid categories img"
                    src={product25}
                    alt="p25"
                  />
                </div>
              </a>
            </li>
          </ul>
        </div>
      </section>

      <FooterNav />

      <section class="panel-space"></section>
    </>
  );
}

export default Categories;
