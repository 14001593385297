import { LOGIN_OTP_VERIFY, SENT_LOGIN_OTP } from "../../../constants/ApplicationUrl";
import Api from "../../../dependencies/utils/Api";

export const AuthService = {
    sentOtp(params) {
        return Api.POST(SENT_LOGIN_OTP, params).then((response) => {
            const { data: {message, status} = {}} = response
            if(status) {
                return {message, status}
            }else {
                return {message, status}
            }
        })
    },
    
    verifyOtp(params) {
        return Api.POST(LOGIN_OTP_VERIFY,params).then((response) => {
            const { data: {message, status} = {}} = response    
            if(status) {
                return {message, status}
            }else {
                return {message, status}
            }
        })
    },
    

}