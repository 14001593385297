import React from "react";
import httpService from "./axios-interceptor";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { routes } from './dependencies/route/index';
import { storage } from "./dependencies/store/storage";
import { useDispatch } from "react-redux";
import { setLogin } from "./dependencies/action";


httpService.setupInterceptors();

const AppRoutes = (props) => {
  const dispatch = useDispatch()
  const token = storage.getJwtToken()
  if(token) {
    dispatch(setLogin())
  }else {
    
  }
  return (
    <BrowserRouter>      
      
      <Routes>
        {routes.map((route, index) => (
          <Route
            key={index.toString()}
            path={route.path}
            element={route.element}
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
