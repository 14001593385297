import React from "react";
import Banner from '../../../assets/images/banner/banner-2.jpg'
import { ArrowRight } from 'iconsax-react';

function BannerSection() {
  return (
    <>
      <section class="banner-wapper">
        <div class="custom-container">
          <div class="banner-bg">
            <img
              class="img-fluid img-bg"
              src={Banner}
              alt="banner-2"
            />
            <div class="banner-content">
              <h2 class="fw-semibold">Best Selling</h2>
              <h4>Comforts & Modern life Stylish Sofa</h4>
            </div>
            <a href="shop.html" class="more-btn">
              <h4>View More</h4>
              <ArrowRight />
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default BannerSection;
