import React from "react";
import like from '../../../assets/images//svg/like.svg'
import likeFill from '../../../assets/images/svg/like-fill.svg'
import product10 from '../../../assets/images/product/10.png'
import product11 from '../../../assets/images/product/11.png'
import product12 from '../../../assets/images/product/12.png'
import product13 from '../../../assets/images/product/13.png'
import { Bag2 } from "iconsax-react";

function OtherFurniture() {
  return (
    <>
      <section class="section-t-space">
        <div class="custom-container">
          <div class="title">
            <h2>Furniture And Decor</h2>
            <a href="shop.html">View All</a>
          </div>

          <div class="row g-4">
            <div class="col-6">
              <div class="product-box">
                <div class="product-box-img">
                  <a href="shop.html">
                    {" "}
                    <img
                      class="img"
                      src={product10}
                      alt="p10"
                    />
                  </a>

                  <div class="cart-box">
                    <a href="cart.html" class="cart-bag">
                      <i class="iconsax bag" data-icon="basket-2"><Bag2 /></i>
                    </a>
                  </div>
                </div>
                <div class="like-btn animate active inactive">
                  <img
                    class="outline-icon"
                    src={like}
                    alt="like"
                  />
                  <img
                    class="fill-icon"
                    src={likeFill}
                    alt="like"
                  />

                  <div class="effect-group">
                    <span class="effect"></span>
                    <span class="effect"></span>
                    <span class="effect"></span>
                    <span class="effect"></span>
                    <span class="effect"></span>
                  </div>
                </div>
                <div class="product-box-detail">
                  <h4>Bubble Swing Chair</h4>
                  <div class="d-flex justify-content-between gap-3">
                    <h5>Modern Swing Chair</h5>
                    <h3 class="fw-semibold">$120</h3>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="product-box">
                <div class="product-box-img">
                  <a href="shop.html">
                    {" "}
                    <img
                      class="img"
                      src={product11}
                      alt="p11"
                    />
                  </a>

                  <div class="cart-box">
                    <a href="cart.html" class="cart-bag">
                      <i class="iconsax bag" data-icon="basket-2"><Bag2 /></i>
                    </a>
                  </div>
                </div>
                <div class="like-btn animate inactive">
                  <img
                    class="outline-icon"
                    src={like}
                    alt="like"
                  />
                  <img
                    class="fill-icon"
                    src={likeFill}
                    alt="like"
                  />
                  <div class="effect-group">
                    <span class="effect"></span>
                    <span class="effect"></span>
                    <span class="effect"></span>
                    <span class="effect"></span>
                    <span class="effect"></span>
                  </div>
                </div>
                <div class="product-box-detail">
                  <h4>Lounge Chair</h4>
                  <div class="d-flex justify-content-between gap-3">
                    <h5>Modern arms chair</h5>
                    <h3 class="fw-semibold">$120</h3>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="product-box">
                <div class="product-box-img">
                  <a href="shop.html">
                    {" "}
                    <img
                      class="img"
                      src={product12}
                      alt="p12"
                    />
                  </a>

                  <div class="cart-box">
                    <a href="cart.html" class="cart-bag">
                      <i class="iconsax bag" data-icon="basket-2"><Bag2 /></i>
                    </a>
                  </div>
                </div>
                <div class="like-btn animate inactive">
                  <img
                    class="outline-icon"
                    src={like}
                    alt="like"
                  />
                  <img
                    class="fill-icon"
                    src={likeFill}
                    alt="like"
                  />

                  <div class="effect-group">
                    <span class="effect"></span>
                    <span class="effect"></span>
                    <span class="effect"></span>
                    <span class="effect"></span>
                    <span class="effect"></span>
                  </div>
                </div>
                <div class="product-box-detail">
                  <h4>Double Bed Sheet</h4>
                  <div class="d-flex justify-content-between gap-3">
                    <h5>Modern double bed sheet</h5>
                    <h3 class="fw-semibold">$120</h3>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="product-box">
                <div class="product-box-img">
                  <a href="shop.html">
                    {" "}
                    <img
                      class="img"
                      src={product13}
                      alt="p13"
                    />
                  </a>

                  <div class="cart-box">
                    <a href="cart.html" class="cart-bag">
                      <i class="iconsax bag" data-icon="basket-2"><Bag2 /></i>
                    </a>
                  </div>
                </div>
                <div class="like-btn animate inactive">
                  <img
                    class="outline-icon"
                    src={like}
                    alt="like"
                  />
                  <img
                    class="fill-icon"
                    src={likeFill}
                    alt="like"
                  />
                  <div class="effect-group">
                    <span class="effect"></span>
                    <span class="effect"></span>
                    <span class="effect"></span>
                    <span class="effect"></span>
                    <span class="effect"></span>
                  </div>
                </div>
                <div class="product-box-detail">
                  <h4>Hanging Light</h4>
                  <div class="d-flex justify-content-between gap-3">
                    <h5>Metal hanging light</h5>
                    <h3 class="fw-semibold">$120</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default OtherFurniture;
