import React from "react";
import product3 from '../../../assets/images/product/3.png'
import product4 from '../../../assets/images/product/4.png'
import product5 from '../../../assets/images/product/5.png'
import start from '../../../assets/images/svg/Star.svg'
import { Bag2 } from "iconsax-react";

function TrandingFurniture() {
  return (
    <>
      <section class="section-t-space">
        <div class="custom-container">
          <div class="title">
            <h2>Trending Furniture</h2>
            <a href="product-details.html">View All</a>
          </div>

          <div class="row g-3">
            <div class="col-12">
              <div class="horizontal-product-box">
                <a href="product-details.html" class="horizontal-product-img">
                  <img
                    class="img-fluid img"
                    src={product3}
                    alt="p3"
                  />
                </a>
                <div class="horizontal-product-details">
                  <div class="d-flex align-items-center justify-content-between">
                    <h4>Wingback Chair</h4>
                    <div class="rating d-flex align-items-center">
                      <img src={start} alt="star" />

                      <h6 class="theme-color fw-normal">4.5</h6>
                    </div>
                  </div>
                  <h5>Modern arms chairs</h5>

                  <div class="d-flex align-items-center justify-content-between mt-1">
                    <div class="d-flex align-items-center gap-2">
                      <h3 class="fw-semibold">$25</h3>
                      <h6 class="save">Save $10</h6>
                    </div>
                    <a href="cart.html" class="cart-bag">
                      <i class="iconsax bag" data-icon="basket-2"><Bag2 /></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="horizontal-product-box">
                <a href="product-details.html" class="horizontal-product-img">
                  <img
                    class="img-fluid img"
                    src={product4}
                    alt="p4"
                  />
                </a>
                <div class="horizontal-product-details">
                  <div class="d-flex align-items-center justify-content-between">
                    <h4>Mid Century Sofa</h4>
                    <div class="rating d-flex align-items-center">
                      <img src={start} alt="star" />

                      <h6 class="theme-color fw-normal">4.5</h6>
                    </div>
                  </div>
                  <h5>Modern arms Sofa</h5>

                  <div class="d-flex align-items-center justify-content-between mt-1">
                    <div class="d-flex align-items-center gap-2">
                      <h3 class="fw-semibold">$999</h3>
                    </div>
                    <a href="cart.html" class="cart-bag">
                      <i class="iconsax bag" data-icon="basket-2"><Bag2 /></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="horizontal-product-box">
                <a href="product-details.html" class="horizontal-product-img">
                  <img
                    class="img-fluid img"
                    src={product5}
                    alt="p5"
                  />
                </a>
                <div class="horizontal-product-details">
                  <div class="d-flex align-items-center justify-content-between">
                    <h4>Beige Chair</h4>
                    <div class="rating d-flex align-items-center">
                      <img src={start} alt="star" />

                      <h6 class="theme-color fw-normal">4.5</h6>
                    </div>
                  </div>
                  <h5>Modern arms chair</h5>

                  <div class="d-flex align-items-center justify-content-between mt-1">
                    <div class="d-flex align-items-center gap-2">
                      <h3 class="fw-semibold">$37</h3>
                    </div>
                    <a href="cart.html" class="cart-bag">
                      <i class="iconsax bag" data-icon="basket-2"><Bag2 /></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TrandingFurniture;
