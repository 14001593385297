import React from "react";
import p1 from '../../../assets/images/product/1.png'
import p2 from '../../../assets/images/product/2.png'
import like from '../../../assets/images/svg/like.svg'
import likeFill from '../../../assets/images/svg/like-fill.svg'
import star from '../../../assets/images/svg/Star.svg'
import { Bag2 } from "iconsax-react";

function NewArivals() {
  return (
    <>
      <section class="section-t-space">
        <div class="custom-container">
          <div class="title">
            <h2>New Arrivals</h2>
            <a href="shop.html">View All</a>
          </div>

          <div class="row g-3">
            <div class="col-6">
              <div class="product-box">
                <div class="product-box-img">
                  <a href="shop.html">
                    <img
                      class="img"
                      src={p1}
                      alt="p1"
                    />
                  </a>

                  <div class="cart-box">
                    <a href="cart.html" class="cart-bag">
                      <i class="iconsax bag" data-icon="basket-2"><Bag2 /></i>
                    </a>
                  </div>
                </div>
                <div class="like-btn animate active inactive">
                  <img
                    class="outline-icon"
                    src={like}
                    alt="like"
                  />
                  <img
                    class="fill-icon"
                    src={likeFill}
                    alt="like"
                  />
                  <div class="effect-group">
                    <span class="effect"></span>
                    <span class="effect"></span>
                    <span class="effect"></span>
                    <span class="effect"></span>
                    <span class="effect"></span>
                  </div>
                </div>
                <div class="product-box-detail">
                  <h4>Buddy Chair</h4>
                  <h5>Modern saddle arms</h5>
                  <div class="bottom-panel">
                    <div class="price">
                      <h4>
                        $14 <del class="pev-price">$20</del>
                      </h4>
                    </div>
                    <div class="rating">
                      <img src={star} alt="star" />
                      <h6>4.5</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="product-box">
                <div class="product-box-img">
                  <a href="shop.html">
                    <img
                      class="img"
                      src={p2}
                      alt="p2"
                    />
                  </a>

                  <div class="cart-box">
                    <a href="cart.html" class="cart-bag">
                      <i class="iconsax bag" data-icon="basket-2"><Bag2 /></i>
                    </a>
                  </div>
                </div>
                <div class="like-btn animate inactive">
                  <img
                    class="outline-icon"
                    src={like}
                    alt="like"
                  />
                  <img
                    class="fill-icon"
                    src={likeFill}
                    alt="like"
                  />
                  <div class="effect-group">
                    <span class="effect"></span>
                    <span class="effect"></span>
                    <span class="effect"></span>
                    <span class="effect"></span>
                    <span class="effect"></span>
                  </div>
                </div>
                <div class="product-box-detail">
                  <h4>Wingback Chair</h4>
                  <h5>Modern saddle arms</h5>
                  <div class="bottom-panel">
                    <div class="price">
                      <h4>
                        $14 <del class="pev-price">$20</del>
                      </h4>
                    </div>
                    <div class="rating">
                      <img src={star} alt="star" />
                      <h6>4.5</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default NewArivals;
