import React from "react";
import { ArrowLeft, Trash, Add, Minus, Bag2 } from "iconsax-react";
import FooterNav from "../Common/FooterNav";

function Wishlist(props) {
    const goBack = (e) => {
        e.preventDefault()
        window.history.back();
      };
  return (
    <>
      <header class="section-t-space">
        <div class="custom-container">
          <div class="header-panel">
            <a onClick={(e) => goBack(e)}>
              <i class="iconsax back-btn" data-icon="arrow-left"><ArrowLeft /></i>
            </a>
            <h3>Wishlist</h3>
          </div>
        </div>
      </header>

      <section>
        <div class="custom-container">
          <div class="row g-3">
            <div class="col-12">
              <div class="horizontal-product-box">
                <a href="#" class="horizontal-product-img">
                  <img
                    class="img-fluid img"
                    src="assets/images/product/18.png"
                    alt="p18"
                  />
                </a>
                <div class="horizontal-product-details">
                  <div class="d-flex align-items-center justify-content-between">
                    <h4>Shiny wooden Chair</h4>
                    <button class="close-button">
                      <i class="iconsax" data-icon="add"></i>
                    </button>
                  </div>
                  <h5>Qty:1</h5>
                  <div class="d-flex align-items-center justify-content-between mt-3">
                    <div class="d-flex align-items-center gap-2">
                      <h3 class="fw-semibold">
                        $130 <del class="light-text fw-normal">$160</del>
                      </h3>
                    </div>
                    <a href="#" class="cart-bag">
                      <i class="iconsax bag" data-icon="basket-2"><Bag2 /></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="horizontal-product-box">
                <a href="#" class="horizontal-product-img">
                  <img
                    class="img-fluid img"
                    src="assets/images/product/19.png"
                    alt="p19"
                  />
                </a>
                <div class="horizontal-product-details">
                  <div class="d-flex align-items-center justify-content-between">
                    <h4>Bedroom Lamp</h4>
                    <button class="close-button">
                      <i class="iconsax" data-icon="add"></i>
                    </button>
                  </div>
                  <h5>Qty:1</h5>
                  <div class="d-flex align-items-center justify-content-between mt-3">
                    <div class="d-flex align-items-center gap-2">
                      <h3 class="fw-semibold">
                        $30 <del class="light-text fw-normal">$60</del>
                      </h3>
                    </div>
                    <a href="#" class="cart-bag">
                      <i class="iconsax bag" data-icon="basket-2"><Bag2 /></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="horizontal-product-box">
                <a href="#" class="horizontal-product-img">
                  <img
                    class="img-fluid img"
                    src="assets/images/product/20.png"
                    alt="p20"
                  />
                </a>
                <div class="horizontal-product-details">
                  <div class="d-flex align-items-center justify-content-between">
                    <h4>Marble Flower Vase</h4>
                    <button class="close-button">
                      <i class="iconsax" data-icon="add"></i>
                    </button>
                  </div>
                  <h5>Qty:1</h5>
                  <div class="d-flex align-items-center justify-content-between mt-3">
                    <div class="d-flex align-items-center gap-2">
                      <h3 class="fw-semibold">
                        $50 <del class="light-text fw-normal">$80</del>
                      </h3>
                    </div>
                    <a href="#" class="cart-bag">
                      <i class="iconsax bag" data-icon="basket-2"><Bag2 /></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterNav />

      <section class="panel-space"></section>
    </>
  );
}

export default Wishlist;
